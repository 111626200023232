// client/src/constants/index.js
import parse from 'html-react-parser';

// per https://medium.com/@austinpaley32/how-to-add-a-constants-file-to-your-react-project-6ce31c015774
// import * as Constants from './constants'
// invoke via: Constants.[CONSTANTNAME]

// examples from article:
// export const DJISYMBOL = "&symbol=DJI"
// export const DeleteButton = require('../images/delete-icon.png')

export const O_COL_SYM = parse("&#12295;");
// &#9675; - a small circle
// &#12295; - a big circle, not far off of an "O"
// &#9711; - the biggest circle

export const X_COL_SYM = parse("&#9587;");
// an 'X' symbol, noticeably larger than "X"

export const VIDEO_SYM = parse("&#127910;");
// 'cinema' symbol for inclusion in Tippy messages about video tool tips

export const QMARK_SYM = parse("&#x2370;");
// unicode for question mark inside of square. I wish 'inside of circle' but ok.

export const TM_SYM = parse("&#x2122;");
// unicode for trademark symbol (TM)

export const ONE_THIRD = parse("&frac13;");
// unicode for 1/3

export const TWO_THIRDS = parse("&frac23;");
// unicode for 2/3

export const THREE_EIGHTHS = parse("&frac38;");
// unicode for 3/8

export const FIVE_EIGHTHS = parse("&frac58;");
// unicode for 5/8

// article notes could create mini files in src/constants, e.g.:
//matcher.js
// then export them from here (index.js) via:
// export * from './matcher';

//two salmon options: FF6F59 or E85D63
//a green complement: 65B362
export const O_BOX_GREEN = "#65B362";
export const O_BOX_RED   = "#FF6F59";
export const O_BOX_PINK  = "#FEC1CB";
export const O_BOX_YELLOW= "#FFFF99";

export const SHOW_SHAREABLE = true;

export const SHOW_AUDIO_UPON_MIN_CHARS = 50;

export const OI_DELETE_BATCH_SIZE = 100;
//export const OI_DELETE_BATCH_SIZE = 1;
export const SMALL_NUM_OF_OIS = 5;

export const SMALL_NUM_OF_SKUS = 1;
export const SMALL_NUM_OF_TRIPS = 1;

export const SMALL_NUM_OF_MENU_ITEMS = 8;

export const TRIP_LABEL_MAX_LENGTH = 42;
export const MAX_SKU_IDENTIFIER_CHARS_BEFORE_TIPPY = 8;
export const DONTSHOW = "DONTSHOW";

export const FEEDBACK_MAX_CHARS = 1000;

// localStorage basenames:
export const LAST_TRIP_SEEN_FOR = "LAST_TRIP_SEEN_FOR_";

export const HAS_UUID_PROFILE
                    = new RegExp(/^[0-9a-f]{8}-([0-9a-f]{4}-){3}[0-9a-f]{12}$/);